import React from 'react';

function App() {
  return (
    <div>
      <h1>Hello, World!</h1>
      {/* 필요한 컴포넌트나 내용을 여기에 추가하세요 */}
    </div>
  );
}

export default App;